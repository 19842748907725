import './index.scss'
// import Logo from '../../../assets/images/threesteps.jpg'
import OwnerLogo from '../../../assets/images/qasim-bayramov.jpg'
import 'react-toastify/dist/ReactToastify.css'
import RandevuForm from '../randevuForm'

const Randevu = () => {
    return (
        <div className="online-randevu" id="online-randevu">
            <div className="content">
                <RandevuForm/>
            </div>
            {/* <div className="info">
                <div className="left-content">
                    <a target="_blank" href="https://www.youtube.com/watch?v=-WZbqcMt28k&feature=share">
                        <i className="pi pi-youtube"/>
                        "Onlayn Randevu"proqramı ilə tanışlıq üçün klikləyin.
                    </a>
                    <a target="_blank" href="tel:+994504713471">
                        <i className="pi pi-phone"/>
                        +994504713471
                    </a>
                    <a target="_blank" href="tel:+994504713471">
                        <i className="pi pi-whatsapp"/>
                        +994504713471
                    </a>
                </div>
                <div className="right-content">
                    <a href="https://threesteps.az" target="_blank">
                        <img width="170" height="100" src={Logo} alt="ThreeSteps"/>
                    </a>
                </div>
            </div> */}
        </div>
    )
}

export default Randevu
