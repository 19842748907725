import {BrowserRouter, Route, Routes} from 'react-router-dom'
import Randevu from './components/pages/randevu'

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route index exact element={<Randevu/>}/>
            </Routes>
        </BrowserRouter>
    )
}

export default App
